import './App.scss';
import Menu from '../Tools/Menu/Menu';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";
import Main from './Main';

// blogs images
import unlock from '../Images/unlock.png'
import reload from '../Images/reload.png'
import GarageImg1 from '../Images/GarageImg1.jpg'
import GarageImg2 from '../Images/GarageImg2.jpg'
import GarageImg3 from '../Images/GarageImg33.jpg'
import GarageImg4 from '../Images/GarageImg4.jpg'
import GarageImg6 from '../Images/GarageImg6.jpg'
import GarageImg7 from '../Images/GarageImg7.jpg'
import GarageImg8 from '../Images/GarageImg8.jpg'
import GarageImg9 from '../Images/GarageImg9.jpg'
import PopUp from '../Tools/PopUp/PopUp.jsx';


function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [showVerify, setShowVerify] = useState(true)
  const [status, setStatus] = useState(true);
  const navigate = useNavigate()
  const location = useLocation()
  const [BookAnAppointment, setBookAnAppointment] = useState(true);
  const url = new URL(window.location.href);
  const [CitiesData, setCitiesData] = useState({});
  const [CitiePhone, setCitiePhone] = useState(url.searchParams.get('cp') ? url.searchParams.get('cp') : "415-740-3681 ");
  const [showPopUp, setShowPopUp] = useState(false)


  // locationsMain
  const locationsMain = [
    {
      "Location": "Austin",
      "Phone": "737-276-0721",
      "Address": [
        {
          "Location1": "Austin",
          "Address1": "3906 N Lamar Blvd, Austin, TX 78756",
        },
        {
          "Location1": "San Antonio",
          "Address1": "459 McCarty Rd, San Antonio, TX 78216",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1ZFzBzklHnjRzKFG32H-Wn9TxmmpdSu8&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Chicago",
      "Phone": "872-231-3387",
      "Address": [
        {
          "Location1": "Chicago",
          "Address1": "1431 W Hubbard St, Chicago, IL 60642",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1Y2d_hgj3Z2rnaUFE90sd_RmsM456iVI&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Connecticut",
      "Phone": "475-250-2288",
      "Address": [
        {
          "Location1": "Hartford",
          "Address1": "367 Main St, Hartford, CT 06106",
        },
        {
          "Location1": "Hamden",
          "Address1": "1382 Dixwell Ave, Hamden, CT 06514",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1arTs8xd0v35vvinYSHJY5y2cU9EPi7g&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Houston",
      "Phone": "346-340-0704",
      "Address": [
        {
          "Location1": "Houston",
          "Address1": "2422 Bartlett St, Houston, TX 77098",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1KYM_Tnx3zFBA9vvUnimOYgc3YHLlLBE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Kansas",
      "Phone": "913-392-9506",
      "Address": [
        {
          "Location1": "Kansas",
          "Address1": "1108 E 30th St, Kansas City, MO 64109",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1cyZUyG54v6vYltik3L6Zbfpd7Cm6gW0&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Los Angeles",
      "Phone": "213-722-3908",
      "Address": [
        {
          "Location1": "Valley",
          "Address1": "16030 Ventura Blvd, Encino, CA 91436",
        },
        {
          "Location1": "Los Angeles",
          "Address1": "2310 Pasadena Ave, Los Angeles, CA 90031",
        },
        {
          "Location1": "Riverside",
          "Address1": "9731 Magnolia Ave, Riverside, CA 92503",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1igOR7boZkzmdyNwUcncKxxA65IggcHE&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Minneapolis",
      "Phone": "651-858-3513",
      "Address": [
        {
          "Location1": "Minneapolis",
          "Address1": "701 N Washington Ave, Minneapolis, MN 55401",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=12fYx_1i1hARDMU9SIht0RVfutlIhFfM&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "New Jersey",
      "Phone": "732-243-8233",
      "Address": [
        {
          "Location1": "Moorestown",
          "Address1": "505 S Lenola Rd, Moorestown, NJ 08057",
        },
        {
          "Location1": "Newark",
          "Address1": "194 Clinton Ave, Newark, NJ 07108",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1wCzvOW3l5LHsYiJ8weh2xoTKcaS8nb8&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Orlando",
      "Phone": "407-890-7611",
      "Address": [
        {
          "Location1": "Orlando",
          "Address1": "6735 Conroy Windermere Rd, Orlando, FL 32835",
        },
        {
          "Location1": "Jacksonville",
          "Address1": "1909 Beach Blvd, Jacksonville Beach, FL 32250",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1NjUXcA6OX2tJZ-Xht56H0wZZNLiGbeQ&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Seattle",
      "Phone": "206-590-6726",
      "Address": [
        {
          "Location1": "Seattle",
          "Address1": "206 Broadway Ave E, Seattle, WA 98102",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1wpt9MtPAqDTtBJ8BGNjpjdKnSZ4IYxA&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Saint Louis",
      "Phone": "314-860-6967",
      "Address": [
        {
          "Location1": "Saint Louis",
          "Address1": "16 Hampton Village, Saint Louis, MO 63109",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=15wIgRS6l1GFxtX5Ongf6ArLwOsAcML0&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Tampa",
      "Phone": "813-722-1865",
      "Address": [
        {
          "Location1": "Tampa",
          "Address1": "5014 E Busch Blvd, Tampa, FL 33617",
        },
        {
          "Location1": "Fort Myers",
          "Address1": "3049 Cleveland Ave, Fort Myers, FL 33901",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/0/embed?mid=1sLBr2KXbf8fs1MWdeT9eGkrQDZ-gqXk&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Denver",
      "Phone": "720-806-6796",
      "Address": [
        {
          "Location1": "Denver",
          "Address1": "562 N Ogden St, Denver, CO 80218",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1-dZYn4IqFRsZTQoHlvmE7CAay5fosYQ&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Charlotte",
      "Phone": "704-476-1479",
      "Address": [
        {
          "Location1": "Charlotte",
          "Address1": "4516 Nations Crossing Rd, Charlotte, NC 28217",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1mhFU5wqgrPgYF_kqrRbwxfZT9SGr_BI&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Nashville",
      "Phone": "615-437-2870",
      "Address": [
        {
          "Location1": "Nashville",
          "Address1": "509 Craighead St, Nashville, TN 37204",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1b1273439kLELw9wSSiqD-_WhlAKGl18&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Dallas",
      "Phone": "469-933-0217",
      "Address": [
        {
          "Location1": "Dallas",
          "Address1": "1151 N Buckner Blvd, Dallas, TX 75218",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1Jq7olr9nIOOlBvrge9gBtQZ1gv38eSk&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Atlanta",
      "Phone": "678-940-8076",
      "Address": [
        {
          "Location1": "Atlanta",
          "Address1": "1181 Hammond Dr NE, Atlanta, GA 30346",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1sIJWXQRLTPmw4YVHmgahsrJWSgd1VEM&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "San Fransisco",
      "Phone": "415-996-0243",
      "Address": [
        {
          "Location1": "San Fransisco",
          "Address1": "345 W Portal Ave, San Francisco, CA 94127",
        },
        {
          "Location1": "Sausalito",
          "Address1": "503 Caledonia St, Sausalito, CA 94965",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1P2j6c4nBHDRM-AtgEDKq0HXXFq0TcIM&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "San Jose",
      "Phone": "408-539-6768",
      "Address": [
        {
          "Location1": "San Jose",
          "Address1": "350 W Julian St, San Jose, CA 95110",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1VTJ5eC5cJ1lvDHm3-xJ-8DaTl412cv8&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Detroit",
      "Phone": "313-923-3217",
      "Address": [
        {
          "Location1": "Detroit",
          "Address1": "2435 E 7 Mile Rd, Detroit, MI 48234",
        }
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1EbsUpXTpW8B457T-xz2aBMfyTDb7EYs&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "New York",
      "Phone": "516-689-7469",
      "Address": [
        {
          "Location1": "New York",
          "Address1": "4133 162nd St, Flushing, NY 11358",
        },
        {
          "Location1": "Deer Park",
          "Address1": "64 Brook Ave, Deer Park, NY 11729",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=17OjSFSNGhqK3w-aOCSkHNgDzV8ybfjc&ehbc=2E312F&noprof=1",
    },
    {
      "Location": "Ohio",
      "Phone": "330-587-9781",
      "Address": [
        {
          "Location1": "Fort Wayne",
          "Address1": "10910 US 24, Fort Wayne, IN 46814",
        },
        {
          "Location1": "Toledo",
          "Address1": "4400 Heatherdowns Blvd, Toledo, OH 43614",
        },
        {
          "Location1": "Dayton",
          "Address1": "5118 Brandt Pike, Dayton, OH 45424",
        },
      ],
      "Map": "https://www.google.com/maps/d/u/3/embed?mid=1T_-ChddVtp0sNncfKQ1_eqiV2h6tCxc&ehbc=2E312F&noprof=1",
    },
  ]


  const navigateTo = (page) => {
    navigate('/' + page)
    setShowMenu(false)

  }
  useEffect(() => {
    (async () => {
      // google //
      if (url.searchParams.get('spg') == "g") {
        const CitiesZipGoogle = (await import('./CitiesZipGoogle.js')).default;
        if (CitiesZipGoogle.find(a => a.ID == url.searchParams.get('cd'))) {
          const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${CitiesZipGoogle.find(a => a.ID == url.searchParams.get('cd')).ZC}&key=AIzaSyC_XBMupYyuYy-f1TGdo32rsoHBhSMWPK0&language=en`, { method: 'GET', })
          const data = await res.json()
          // console.log(data.results[0])
          setCitiesData({
            "ID": CitiesData.ID,
            "City":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),
            "Full":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name + ', ' + data.results[0].address_components.find(a => a.types.find(a => a == 'administrative_area_level_1')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),

          })
        } else {
          const CitiesDataGoogle = (await import('./CitiesDataGoogle.js')).default;
          if (CitiesDataGoogle.find(a => a.ID == url.searchParams.get('cd'))) {
            setCitiesData(CitiesDataGoogle.find(a => a.ID == url.searchParams.get('cd')))
          } else {
            setCitiesData({})
          }
        }
      }

      // Microsoft //
      if (url.searchParams.get('spg') == "m") {
        console.log("yes");
        const CitiesZipMS2 = (await import('./CitiesZipMS2.js')).default;
        if (CitiesZipMS2.find(a => a.ID == url.searchParams.get('cd'))) {
          const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${CitiesZipMS2.find(a => a.ID == url.searchParams.get('cd')).ZC}&key=AIzaSyC_XBMupYyuYy-f1TGdo32rsoHBhSMWPK0&language=en`, { method: 'GET', })
          const data = await res.json()
          setCitiesData({
            "ID": CitiesZipMS2.find(a => a.ID == url.searchParams.get('cd')).ID,
            "City":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),
            "Full":
              data.results[0].address_components.find(a => a.types.find(a => a == 'locality')) ?
                data.results[0].address_components.find(a => a.types.find(a => a == 'locality')).long_name + ', ' + data.results[0].address_components.find(a => a.types.find(a => a == 'administrative_area_level_1')).long_name :
                data.results[0].formatted_address.split(',')[0] + ", " + data.results[0].formatted_address.split(',')[1].substring(0, 3),

          })
        } else {
          const CitiesDataMS = (await import('./CitiesDataMS.js')).default;
          if (CitiesDataMS.find(a => a.ID == url.searchParams.get('cd'))) {
            setCitiesData(CitiesDataMS.find(a => a.ID == url.searchParams.get('cd')))
          } else {
            setCitiesData({})
          }
        }
      }
    })();
    if (showVerify == true) {
      document.body.style.overflow = "hidden"
      document.body.style.overflow = "scroll"
    } else {
      document.body.style.overflow = "scroll"
    }

    window.addEventListener('online', () => setStatus(true))
    window.addEventListener('offline', () => setStatus(false))

  }, [showVerify, status]);

  return (
    <div className="App" style={false ? { "document.html.style.overflow": "hidden" } : null}>
      {/* <Online>Only shown when you're online</Online> */}
      {/* {!status ? */}
      <Offline>
        <div className="OfflineApp">
          <h1><img src={unlock} alt="" /><span>Internet Connection Is Not Stable.</span>Your network seems to be disconnected. Please check your internet connection.</h1>
          <img onClick={() => window.location.reload()} className='OfflineAppClose' src={reload} alt="" />
        </div>
      </Offline>
      {/* : null} */}

      {/* PopUp */}
      {/* {showPopUp ?
        <PopUp CitiePhone={CitiePhone} CitiesData={CitiesData} setShowPopUp={setShowPopUp} />
        : null} */}
      {/* Menu */}
      {showMenu ? <Menu  navigateTo={navigateTo} setShowMenu={setShowMenu} /> : null}
      {/* Main */}
      <Main showMenu={showMenu} setShowPopUp={setShowPopUp} setShowMenu={setShowMenu} CitiesData={CitiesData} CitiePhone={CitiePhone} locationsMain={locationsMain}  navigateTo={navigateTo} />

      {/* oNLINE */}
      {location.pathname == "/book" ? null : <a className='callButtonMainApp' href={`tel:${CitiePhone}`}> Click To Call! <img src={require(`../Images/telephone.png`)} />{CitiePhone}</a>}

    </div>
  );
}

export default App;
